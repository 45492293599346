.spinner-container {
  display: flex;
  justify-content: center;
  padding: 3rem 0;
  width: 100%;
}

.spinner {
  animation: spin 1s ease-in-out infinite;
  border: 4px solid;
  border-radius: 50%;
  display: inline-block;
  height: 3em;
  width: 3em;
}

@keyframes spin {
  to { 
    transform: rotate(360deg);
  }
}

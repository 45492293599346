@import '../../../assets/scss/index';

.herointro {
  align-items: center;
  background-image: url('../../../assets/images/img010.jpeg');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  color: $primary-color;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: calc(100vh - 56px);
}

.herointro-textbackdrop {
  align-items: center;
  animation: fadein 3s;
  background-image: linear-gradient(rgba(1, 1, 1, 0), #111);
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  width: 100%;
  
  h1,
  h2 {
    margin: 5px;
    text-align: center;
    text-shadow: 1px 1px 2px $secondary-color;
  }
  
  h1 {
    font-size: 2.5rem;
  };

  a {
    color: $primary-color;
    font-size: 1.5em;
    margin: 0 10px;
  };
}

@media only screen and (min-width: 1170px) {
  .herointro {
    background-attachment: fixed;
    min-height: 100vh;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

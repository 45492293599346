@import '../../../assets/scss/index';

.navitem {
  box-sizing: border-box;
  color: $primary-color;
  display: block;
  margin: 10px 0;
  width: 100%;

  > span {
    box-sizing: border-box;
    color: $secondary-color;
    cursor: pointer;
    display: block;
    text-decoration: none;
    transition: all 0.5s ease-out;
    width: 100%;
  }

  .navitem-link-active {
    border-bottom: 4px solid $active-color;
    color: $active-color;
  }
}

@media (min-width: 500px) {
  .navitem {
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0;
    width: auto;

    > span {
      border-bottom: 4px solid transparent;
      color: $primary-color;
      height: 100%;
      padding: 16px 10px;
      transition: all 0.5s ease-out;
    }
  }
}

@media (hover: hover) and (pointer: fine) {
  .navitem > span:hover {
    border-bottom: 4px solid $hover-color;
    color: $hover-color;
  }
}

@import '../../../assets/scss/index';

.footer {
  align-content: center;
  background-color: $secondary-color;
  color: $primary-color;
  display: flex;
  justify-content: center;
  text-align: justify;
}

@import '../../../assets/scss/index';

.toolbar {
  align-items: center;
  background-color: $secondary-color;
  box-sizing: border-box;
  display: flex;
  height: 56px;
  justify-content: space-between;
  left: 0;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 90;

  nav {
    height: 100%;
  }
}

.toolbar-logo {
  cursor: pointer;
  height: 80%; 
}

.toolbar-logo-icon {
  height: 100%;

  img {
    border-radius: 30px;
    height: 100%;
    width: 44px;
  }
}

.drawer-toggle {
  align-items: center;
  box-sizing: border-box;
  color: $primary-color;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: space-around;
  width: 20px;

  svg {
    font-size: 2rem;
  }
}

@media (max-width: 499px) {
  .desktop-only-nav {
    display: none;
  }
}


@media (min-width: 500px) {
  .drawer-toggle {
    display: none;
  }
}

.profesional {
  display: flex;
  flex-direction: column;
  margin: auto;
  // overflow-x: hidden;
  width: 90%;
  
  section {
    padding-top: 65px;
  }
  
  > :last-child {
    padding-bottom: 56px;
  }
}

.about-section-title {
  display: flex;
  justify-content: center;
  margin: 0;
}

.timeline-section-title {
  display: flex;
  justify-content: center;
  margin: 0;
}

.skills-section-title {
  display: flex;
  justify-content: center;
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .timeline-section-title {
    padding-bottom: 56px;
  }
}

body {
  font-family: 'Open Sans', 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
}

html {
  line-height: 1.6;
  scroll-behavior: smooth;
}

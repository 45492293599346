@import '../../../assets/scss/index';

.sidedrawer {
  background-color: $primary-color;
  box-sizing: border-box;
  height: 100%;
  left: 0;
  max-width: 70%;
  padding: 32px 16px;
  position: fixed;
  top: 0;
  transition: transform 0.3s ease-out;
  width: 280px;
  z-index: 200;
}

.sidedrawer-open {
  transform: translateX(0);
}

.sidedrawer-close {
  transform: translateX(-100%);
}

.sidedrawer-logo {
  height: 11%;
  margin-bottom: 32px;
}

.sidedrawer-logo-icon {
  height: 100%;

  img {
    border-radius: 30px;
    height: 100%;
    width: auto;
  }
}

@media (min-width: 500px) {
  .sidedrawer {
    display: none;
  }
}

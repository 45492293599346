.section-title-span {
  background-image: linear-gradient(to right, rgba(255, 225, 0, 0.1), rgba(255, 204, 0, 0.7) 4%, rgba(255, 225, 0, 0.3));
  background-position: 0 88%;
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  border-radius: 0.8em 0.3em;
  padding: 0.1em 0.4em;
  transition: background-size 0.25s ease-in;
}

@media (hover: hover) and (pointer: fine) {
  .section-title-span:hover {
    background-size: 100% 88%;
  }
}
